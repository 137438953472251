export const environment = {
  production: false,
  UserValidationLookupUrl:"https://hfdigitaltest.azure-api.net/heart-uil/UserValidation",
  UpdateHeartCaseUrl:"https://hfdigitaltest.azure-api.net/heart-uil/UpdateHeartCase",
  MailAssistantUrl:"https://hfdigitaltest.azure-api.net/heart-uil/SendEmail",
  captchaKey: "6Ldu4wIeAAAAAAykkkh5x_xcapfaFkSXhWH9VdMj",
  ValidatedEnvironment: 5,
  IsOffline: false,
  errorMessage: " "
};
